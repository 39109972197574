import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 100px);
    min-height: 550px;
    .indexWrapper {
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
      gap: 4px 12px;
      width: auto;
      height: 100%;
      padding: 4px 8px 0px;
      overflow-y: auto;
      background: rgb(42, 45, 55);
      border-radius: 6px 6px 0px 0px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 98%;
      min-height: 400px;
      border-radius: 6px 0px 6px 6px;
      padding: 6px;
      margin-top: auto;
      background: rgb(42, 45, 55);
      transition: all 0.2s ease 0s;
      .itemContent {
        display: flex;
        width: 100%;
        flex: 1 1 0%;
        background: rgb(85, 91, 109);
        padding: 8px;
        flex-direction: column;
        .title {
          display: flex;
          -webkit-box-pack: start;
          justify-content: flex-start;
          width: 100%;
          height: auto;
          max-height: 110px;
          overflow-y: auto;
          color: rgb(255, 255, 255);
          padding: 0.5rem;
          margin-bottom: 1rem;
          border-radius: 6px 6px 0px 0px;
          background: rgb(85, 91, 109);
          .finalTitle {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            h2 {
              font-size: 16px;
              white-space: pre-line;
              max-width: 100%;
              text-align: justify;
              font-weight: 400;
              font-family: Roboto, sans-serif;
            }
          }
        }
            .item-container {
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 100%;
              border-radius: 4px;
            }

            .item-text {
              margin-left: 30px;
              word-break: break-word;
              overflow-wrap: break-word; 
              white-space: normal; 
            }
        .finalDiv {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: fit-content;
          border-radius: 4px;
          background: rgb(222, 223, 224);
          .finWrapper {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 1rem;
            h2 {
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              margin-top: auto;
              margin-bottom: auto;
            }
            .options {
              display: flex;
              -webkit-box-pack: start;
              justify-content: flex-start;
              width: 100%;
              height: 100%;
              padding: 0px 12px;
              .column {
                display: flex;
                flex-direction: column;
                -webkit-box-pack: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                gap: 1rem;
                .correct {
                  padding: 4px;
                  border-radius: 4px;
                  background: rgba(2, 245, 51, 0.5);
                  pointer-events: none;
                  display: flex;
                  align-items: center;
                  width: 100%;
                }
                .wrong {
                  padding: 4px;
                  border-radius: 4px;
                  background: rgba(245, 66, 66, 0.4);
                  pointer-events: none;
                  display: flex;
                  align-items: center;
                  width: 100%;
                }
                .unmarked {
                  padding: 4px;
                  border-radius: 4px;
                  pointer-events: none;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
    .answer {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      min-height: 8%;
      padding: 8px;
      border-radius: 0px 0px 6px 6px;
      background: rgb(85, 91, 109);
      button {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        align-self: center;
        border-radius: 6px;
        height: 54px;
        margin-top: 0px;
        background: rgb(63, 232, 105);
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 30px;
  color: rgb(255, 255, 255);
  margin-left: auto;
  h4 {
    text-align: center;
    margin-right: 12px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 0px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #363636;
  height: 12%;
  p {
    margin-left: 12px;
    font-size: 14px;
  }
`;

export const IMG = styled.div`
  display: flex;
  margin-top: 10vh;
  margin-right: 4vw;
  height: 200px;
  width: 700px;
  max-height: 100%;
  img {
    max-width: 100%;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  justify-self: center;
  width: 32px;
  height: 16px;
  border-radius: 4px;
  transition: background-color 0.2s ease 0s;
  .stats {
    margin-top: 10%;
    height: 80%;
    display: block;
    padding-top: 20%;
  }
  .selected {
    background: rgb(242, 205, 98);
    color: rgb(0, 0, 0);
  }
`;
